.App {
  display: flex;
  flex: 1;
  flex-direction: column;

  /*
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../src/img/stone-wall-and-floor.jpg);
  height: auto; */
}

p {
  color: black;
}
